.main {
  width: 100%;
  height: 100%;

  .header {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    padding: 10px;
  }

  .input {
    width: 350px;
  }

  :global(.ant-table-thead .ant-table-cell) {
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;

    &:nth-last-of-type(1) {
      // text-align: center;
    }
  }

  :global(.ant-table-row-level-0 .ant-table-cell) {
    &:nth-last-of-type(1), &:nth-last-of-type(4) {
      // text-align: center;
    }
  }

  :global(.ant-pagination) {
    align-items: center;
  }
}

.filterBar {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;

  .levelFilter {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .title {
    min-width: 43px;
    margin-left: 10px;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .input {
    min-width: 350px;
  }
}

.complaintTable.empty {
  margin-top: 20px;
}

.link {
  color: #1890FF;
}