@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, hr, p, figure {
    display: block;
    font-size: 1em;
    font-weight: normal;
    margin: 0;
    border-width: 0;
    opacity: 1;
}

ul {
    display: block;
    margin: 0;
    padding: 0;
}

li {
    display: block;
}

button {
    background-color: transparent;
    border: none;
    display: block;
    padding: 0;
    margin: 0;
    align-items: normal;
}

body {
    margin: 0;
    font-family: "Noto Sans TC", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.page-header {
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.sticking {
    margin: 0 !important;
    z-index: 10000 !important;
}

.wrap {
    overflow-wrap: break-word;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    word-break: break-all;
}

@media (max-width: 99999px) {
    .max\:show {
        display: flex;
        flex-direction: column;
    }

    .xxxl\:show {
        display: none;
    }

    .xxl\:show {
        display: none;
    }

    .xl\:show {
        display: none;
    }

    .lg\:show {
        display: none;
    }

    .md\:show {
        display: none;
    }

    .sm\:show {
        display: none;
    }

    .xs\:show {
        display: none;
    }

    .max\:hide {
        display: none;
    }
}

@media (max-width: 2999px) {
    .xxxl\:show {
        display: flex;
    }

    .xxxl\:hide {
        display: none;
    }
}

@media (max-width: 1919px) {
    .xxl\:show {
        display: flex;
    }

    .xxl\:hide {
        display: none;
    }
}

@media (max-width: 1399px) {
    .xl\:show {
        display: flex;
    }

    .xl\:hide {
        display: none;
    }
}

@media (max-width: 1199px) {
    .lg\:show {
        display: flex;
    }

    .lg\:hide {
        display: none;
    }
}

@media (max-width: 991px) {
    .md\:show {
        display: flex;
    }

    .md\:hide {
        display: none;
    }
}

@media (max-width: 767px) {
    .sm\:show {
        display: flex;
    }

    .sm\:hide {
        display: none;
    }
}

@media (max-width: 575px) {
    .xs\:show {
        display: flex;
    }

    .xs\:hide {
        display: none;
    }
}

.ant-btn-primary:hover {
    color: #fff !important;
    background-color: hsl(186, 100%, 30%) !important;
}

.ant-switch-inner {
    text-wrap: nowrap;
}

.material-symbols-outlined {
    font-family: 'Material Symbols Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}


.ant-pagination-item {
  display: none !important;
}

.ant-pagination-item-link {
  border: 1px solid #D9D9D9 !important;
}

.ant-collapse-header {
    display: flex;
    align-items: baseline !important;
}

.ant-carousel .slick-dots li button {
    background-color: #AAA !important;
}
.ant-carousel .slick-dots li.slick-active button {
    background-color: #00A5B8 !important;
}

.requiredFormItemWithExtraNote .ant-form-item-required::before {
    align-self: flex-start;
    line-height: 22px !important;
}

.complaintRecordDetailModal .ant-modal-content, .complaintRecordDetailModal .ant-modal-header, .complaintRecordDetailModal .ant-modal-footer {
    background-color: #fff;
}

@media (max-width: 768px) {
    .complaintRecordDetailModal .ant-modal-content {
        padding: 20px;
    }
}