@use "src/utils" as *;
@use "src/global" as *;

.item {
  @include heading(14px, 300);
  border-bottom: 1px solid $border-color;
  white-space: pre-line;
  display: grid;
  padding: 2px;
  grid-template-columns: 150px minmax(150px, 1fr);
  grid-column: span var(--span);

  @include md {
    grid-column: span 1;
  }

  @include tn {
    grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr);
  }

  .key {
    padding: 16px;
    background-color: #F5F5F5;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .value {
    padding: 16px 20px;
    flex: 1;
    display: flex;
    align-items: center;
    word-wrap: break-word;
    word-break: break-all;
    width: 100%;
  }
}
