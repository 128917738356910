@use "src/utils" as *;
@use "src/global" as *;

.complaintRecordDetailModal {
  background-color: #fff;

  .title {
    text-align: center;
    font-size: 18px;
    color: $heading-color;
  }

  .footer {
    display: flex;
    justify-content: center;

    .button {
      @include primary-btn;
    }
  }

  .table {
    display: grid;
    border-top: 1px solid $border-color;
    grid-template-columns: repeat(6, 1fr);
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;

    @include md {
      grid-template-columns: 1fr;
    }

    @media (max-width: 330px) {
      overflow-x: auto;
    }

    .gridItem {
      @include xxs {
        grid-template-columns: 111px minmax(150px, 1fr);
      }
    }

    .gridItemLabel {
      @include xxs {
        padding: 16px 5px;
      }
    }
  
    @include complaint-level-tags();

    .issues {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;

      .issue {
        @include gray-bordered-tag;
        line-height: 1;
      }
    }

    .files {
      display: flex;
      flex-direction: column;
  
      .file {
        text-decoration: none;
        font-size: 14px;
        line-height: 22.4px;
  
        .paperclip {
          color: #00000073;
          margin-right: 8px;
        }
  
        span {
          color: $primary-color;
          font-weight: 500;
        }
      }
    }

    .matchPercentageValueWrapper {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  .loadingBox {
    min-height: 450px;
    padding-top: 0px;
    display: grid;
    place-content: center;
  }

  
}

.tooltipContent {
  color: $heading-color;
  padding: 12px 16px;
}