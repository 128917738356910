@use "src/global" as *;
@use "src/utils" as *;

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    padding: 10px;

    .title {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
  }

  .mainContentContainer {
    position: relative;
    flex-grow: 1;

    &::before {
      content: '';
      display: block;
      background-color: #fff;
      width: calc(100% + 32px);
      height: 100%;
      position: absolute;
      top: 0px;
      left: -16px;
    }
  }

  .mainContent {
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    max-width: 984px;
    margin: 10px auto 20px;
  }

  .resultSection {
    .resultTitle {
      font-size: 18px;
      color: $heading-color;
    }

    .formButtons {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 12px;
      margin-top: 12px;

      .formButton {
        width: 146px;
      }
    }
  }

  .uploadFilePanel {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .uploadButtonAndNote {
      display: flex;
      align-items: center;
      gap: 12px;
      font-weight: 300;
    }

    .files {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }

  .section {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .label {
      padding: 0px;
    }

    .reportedAt {
      color: $primary-color;
    }
  }

  .sectionTitle {
    font-size: 18px;
    color: $heading-color;
    margin: 10px 0px;
  }

  .reporteeGrid {
    grid-template-columns: repeat(3, 1fr);

    @include md {
      grid-template-columns: minmax(0px, 1fr);
    }
  }

  .contentShortListGrid {
    grid-template-columns: 1fr 2fr;

    @include md {
      grid-template-columns: minmax(0px, 1fr);
    }
  }

  .bottomButtonContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .cancelButtonWrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    max-width: 194px;

    .cancelButtonNote {
      color: $tag-color-red;
      font-size: 14px;
      line-height: 1.6;
      font-weight: 300;
      text-align: center;
    }
  }

  .matchPercentageWrapper {

    :global(ant-form-item-margin-offset) {
      display: none;
    }
  }

  .formRow {
    display: flex;
    gap: 40px;

    @include xs {
      flex-direction: column;
      gap: 0px;
    }
  
    div {
      flex-grow: 1;
    }
  }
}
