@use "src/utils" as *;
@use "src/global" as *;

.card {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  // padding: 12px 24px 12px 12px;
  gap: 12px;
  // background-color: #fff;
  // border: 1px solid $border-color;
  // align-self: flex-start;

  .paperclip {
    margin-top: 3px;
  }

  .info {
    display: flex;
    flex-direction: column;
  }

  .name {
    flex: 1;
    color: $primary-color;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.4;
  }

  .text {
    color: #3F3F46;
    font-size: 12px;
    font-weight: 300;
    line-height: 19.2px;
  }

  .failed {
    color: $danger-color;
  }

  .opacity20 {
    opacity: 0.2;
  }

  .clear {
    position: absolute;
    top: 10px;
    right: 12px;

    .x {
      fill: $subheading-color;
    }

    &:active {
      border: none;
    }
  }

  .topHalf {
    display: flex;
    align-items: flex-start;
    gap: 8px;
  }

  .endHalf {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.clickable {
  cursor: pointer;

  &:hover {
    .name {
      color: darken($primary-color, 10%);
    }
  }
}
