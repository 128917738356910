@use "src/utils" as *;
@use "src/global" as *;

.main {
  @include main;
}

.box {
  @include box(unset);
  margin-top: $headerGap;
  display: flex;
  row-gap: 10px;
  column-gap: 10px;

  @include sm {
    flex-direction: column;
  }

  .leftCol {
    display: flex;
    flex-direction: column;
    flex: 0 1 480px;

    @include md {
      flex: 1;
    }

    .imgBox {
      width: 100%;
      aspect-ratio: 1/1;
      margin-bottom: 30px;

      @include sm {
        margin-bottom: 10px;
      }

      .img {
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
      }
    }

    .name {
      @extend %cat-name;

      @include sm {
        align-self: flex-start;
        order: -1;
        margin-bottom: 8px;
      }
    }
  }

  .rightCol {
    flex: 1;
  }
}


.btn {
  @include primary-btn;
  margin-top: 16px;
  width: 280px;

  @include sm {
    width: 100%;
  }
}

.bgGray {
  background-color: $subheading-color;
}

.row {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;

  @include md {
    flex-direction: column;
  }

  .btn {
    width: 100%;
    flex: 1;
  }
}

.row2 {
  display: flex;
  column-gap: 10px;
}

.text {
  @extend %context;
  margin-top: 16px;
  color: $primary-color;
}

.contractsWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.downloadBtn {
  padding: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  text-decoration: none;
  height: fit-content;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  color: $primary-color;
}