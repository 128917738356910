@use "src/utils" as *;
@use "src/global" as *;

.main {
  @include main;
}

.box {
  @include box(unset);
  margin-top: $headerGap;
  display: flex;
  row-gap: 10px;
  column-gap: 10px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  max-width: 880px;
  margin: 46px auto 20px;

  @include sm {
    flex-direction: column;
  }

  @include complaint-level-tags();

  .statusTag {
    @include gray-bordered-tag;
    padding: 8px 12px;
  }
  .statusTag.completed {
    border-color: $primary-color;
    color: $primary-color;
  }
}

.section {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .sectionTitle {
    font-size: 18px;
    color: $heading-color;
    margin: 10px 0px;
  }

  .label {
    padding: 0px;
  }

  .reportedAt {
    color: $primary-color;
  }
}



.row {
  display: flex;
  gap: 24px;
  align-items: center;
}

.threeColGrid {
  grid-template-columns: repeat(3, minmax(293px, 1fr));

  @include md {
    grid-template-columns: minmax(0px, 1fr);
  }
}