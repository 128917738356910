@use "src/utils" as *;
@use "src/global" as *;

.main {
  @include main;
}

.box {
  @include box(unset);
  margin-top: $headerGap;
  max-width: 880px;
  @extend %context;

  position: relative;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.formGap {
  margin-bottom: 24px;
}

.fieldGap {
  padding-bottom: 8px;
}

.extra {
  @extend %context-light;
  color: $special-color;
}

.bold {
  font-weight: 700;
}

.section {
  color: $heading-color;
  .sectionTitle {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 24px;
  }
}

.submitButtonWrapper {
  align-self: flex-end;
  display: inline-block;
  z-index: 1000;
  left: 100%;
  bottom: 50px;
  position: sticky;

  @include sm {
    width: 100%;
  }

  .col {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 880px) {
    right: 20px;
  }

  .guidelinesButton {
    @include link-btn;
    margin: 0 auto;
  }

  .submitButton {
    @include primary-btn;
    width: 160px;

    @include sm {
      width: 100%;
    }
  }
}

.fileList {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 5px;
}
