@use "src/utils" as *;
@use "src/global" as *;

.infoIcon {
  color: $primary-color;
  font-size: 14px;
  font-weight: 500;
  line-height: 22.4px;
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  color: $heading-color;
  font-size: 18px;
  gap: 8px;
  text-align: center;
}

.yesBtn {
  @include primary-btn;
  width: 160px;
}

.footer {
  gap: 16px;
}

.content {
  color: $heading-color;
  margin: 20px 20px 32px;
  white-space: pre-wrap;
  font-size: 14px;
  font-weight: 300;
  line-height: 22.4px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-weight: 300;

  @include sm {
    margin: 0px 0px 20px;
  }

  p {
    font-weight: inherit;
  }

  .section {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .sectionTitle {
      font-weight: 500;
    }

    .bulletList {
      margin-left: 3em;

      @include sm {
        margin-left: 1em;
      }

      li {
        &::before {
          content: "•";
          font-weight: 300;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
        }
      }
    }
  }
}