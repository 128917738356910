@use "src/utils" as *;
@use "src/global" as *;

.main {
  @include main;
  display: flex;
  flex-direction: column;
  flex: 1;

  .box {
    @include box(unset);
    padding-top: $headerGap;
    display: flex;
    flex-direction: column;
    gap: 12px;
    flex: 1;
  }

  .reportButton {
    @include primary-btn;
    @include btn-with-icon;

    @include sm {
      width: 100%;
    }
  }

  @include complaint-level-tags();
  .levelTag {
    justify-content: center;
  }

  .statusTag {
    @include gray-bordered-tag;
    display: inline-block;
    min-width: 60px;
    text-align: center;
  }

  .statusTag.completed {
    border-color: $primary-color;
    color: $primary-color;
  }
}

.noDataBox {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 1;

  .noData {
    font-family: Noto Sans TC;
    font-size: 28px;
    font-weight: 300;
    line-height: 45px;
    letter-spacing: 1px;
    text-align: center;
    color: $heading-color;
  }
}

.tableRow {
  cursor: pointer;
}

.mainContent {
  .updatedAtColumn {
    @include sm {
      display: none;
    }
  }

  .complaintsTable {
    :global(.ant-table-cell) {
      text-align: center;

      @include sm {
        padding: 16px 8px;
      }

      @media (max-width: 380px) {
        padding: 16px 4px;
      }
    }

    .dateColumn {
      @include xxs {
        width: 85px;
      }
    }
  }
}