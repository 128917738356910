@use "src/utils" as *;
@use "src/global" as *;


.reporteeGrid {
  grid-template-columns: repeat(3, minmax(293px, 1fr));

  @include md {
    grid-template-columns: minmax(0px, 1fr);
  }
}

.contentShortListGrid {
  grid-template-columns: 1fr 2fr;

  @include md {
    grid-template-columns: minmax(0px, 1fr);
  }
}

.section {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .sectionTitle {
    font-size: 18px;
    color: $heading-color;
    margin: 10px 0px;
  }

  .label {
    padding: 16px 8px;
  }

  .issues {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    .issue {
      @include gray-bordered-tag;
    }
  }

  .files {
    display: flex;
    flex-direction: column;

    .file {
      text-decoration: none;
      font-size: 14px;
      line-height: 22.4px;

      .paperclip {
        color: #00000073;
        margin-right: 8px;
      }

      span {
        color: $primary-color;
        font-weight: 500;
      }
    }
  }

  .reportedAt {
    color: $primary-color;
  }
}

.targetTable {
  display: grid;
  border-top: 1px solid $border-color;
  grid-template-columns: repeat(6, 1fr);
  max-height: 500px;

  @include md {
    grid-template-columns: 1fr;
  }

  .gridItemLabel {
    padding: 16px 5px;
  }
}