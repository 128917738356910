@use "src/utils" as *;
@use "src/global" as *;

$color_type_0: rgb(168, 162, 158);

$bg_color_type_0: rgb(245, 245, 245);

$padding_type_0: 32px 16px 32px 16px;

$font_type_0: 500 16px/1.25 "Noto Sans TC", Helvetica, Arial, serif;
$font_type_1: 500 14px/1.42 "Noto Sans TC", Helvetica, Arial, serif;
$font_type_2: 500 1em/1.42 "Noto Sans TC", Helvetica, Arial, serif;
$font_type_normal: 400 1em/1.42 "Noto Sans TC", Helvetica, Arial, serif;

.content_box4Content {
  flex-shrink: 0;
  margin-top: 160px;
  background-color: $bg_color_type_0;
  display: flex;
  flex-direction: column;
  padding: $padding_type_0;
  position: relative;
}
.container {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}
.light {
  background-color: #FFF;
}
.flex_row {
  display: flex;
  gap: 0px 8px;
  justify-content: space-between;
  max-width: 1440px;
  position: relative;
  width: 100%;
  @include lg {
    aspect-ratio: auto;
    flex-wrap: wrap;
    gap: 10px 8px;
    justify-content: flex-start;
    margin: 0px auto;
  }
}
.footerColumn {
  display: flex;
  flex-direction: column;
  gap: 10px 0px;
  min-width: 0px;
  position: relative;
  // width: 248px;
  @include lg {
    column-gap: 0px;
    flex: 0 0 auto;
    justify-content: center;
    width: 100%;
  }
}
.highlight2 {
  color: $color_type_0;
  display: flex;
  font: $font_type_0;
  letter-spacing: 0px;
  @include lg {
    justify-content: center;
    text-align: center;
  }
}
.text1 {
  color: $color_type_0;
  font: $font_type_1;
  letter-spacing: 0px;
  @include lg {
    text-align: center;
  }
}
.text1_box {
  align-items: flex-end;
  display: flex;
  @include lg {
    justify-content: center;
  }
}
.text1_span0 {
  font: $font_type_2;
}
.text1_span1 {
  font: $font_type_2;
}
.text11 {
  color: $color_type_0;
  font: $font_type_1;
  letter-spacing: 0px;
  text-align: right;
  @include lg {
    text-align: center;
  }
}
.text11_box {
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  margin: 30px 0px 0px;
  min-width: 0px;
  width: 373px;
  @include lg {
    flex: 0 0 auto;
    justify-content: center;
    margin: 0px;
    width: 100%;
  }
}
.text11_span0 {
  font: $font_type_normal;
}
.text11_span1 {
  font: $font_type_normal;
  text-decoration: underline;
  display: inline-block;
  color: $color_type_0;
}

.image {
  bottom: 92px;
  object-fit: cover;
  position: absolute;
  right: 15.42%;
  top: -112px;
  width: 327px;
}