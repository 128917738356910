@use "src/utils" as *;
@use "src/global" as *;

.card {
  padding: 16px;
  border: 1px solid $border-color;
  font-size: 14px;
  font-weight: 300;
  color: $heading-color;

  display: flex;
  flex-direction: column;
  gap: 8px;

  @include tn {
    padding: 12px;
  }

  .topRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
  
    @include complaint-level-tags();
  }
  
  .issues {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  
    .issue {
      @include gray-bordered-tag;
    }
  }

  .description {
    line-height: 1.8;
  }

  
  .footer {
    border-top: 1px solid $border-color;
    padding-top: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .viewDetailButton {
      align-self: flex-end;
      @include primary-btn;
      background-color: $subheading-color;
      width: 80px;
      height: auto;
    }
  }
}

