@use "src/utils" as *;
@use "src/global" as *;

.main {
  background-color: $background-color;
}

.box {
  background-color: $background-color;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  width: min(535px, 90vw);
  margin: 100px 0;
  padding: 30px;
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-family: Noto Sans TC;
  font-size: 32px;
  font-weight: 700;
  line-height: 51px;
  letter-spacing: 0px;
  margin: 0;
  text-align: center;
}

.row {
  @extend %context;
  width: 100%;
  margin-top: 30px;
}

.field {
  padding: 0 16px;
  margin-bottom: 5px;
}

.primaryBtn {
  @include primary-btn;
  width: 100%;
  margin-top: 30px;
}

.secondaryBrn {
  @extend %context-light;
  background-color: #FFF;
  padding: 8px;
  border-radius: 2px;
  text-align: center;
  height: 40px;
  width: 100%;
  border: 1px solid $border-color;
  margin-top: 10px;
}

.text {
  @extend %context-light;
}

.link {
  @extend %context-light;
  color: $primary-color;
  text-decoration: underline;
}

.alignRight {
  margin-top: 10px;
  align-self: flex-end;
}

.infoModal {
  :global(.ant-modal-content) {
    border-radius: 0px;
  }

  :global(.ant-modal-confirm-paragraph) {
    max-width: none;
  }

  :global(.ant-modal-confirm-btns) {
    text-align: center;
    margin-top: 16px;
  }

  .infoModalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    font-size: 14px;
    color: $heading-color;
    font-weight: 300;

    .titleRow {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .icon {
      color: $primary-color;
    }

    .title {
      font-weight: 500;
      font-size: 14px;
      line-height: 1.6;
    }
  }

  .okBtn {
    @include primary-btn;
    width: 80px;
    min-height: 28px;
    height: auto;
    padding: 5px 8px;
  }
}
