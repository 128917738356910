@use "src/utils" as *;
@use "src/global" as *;


.table {
  border-top: 1px solid $border-color;
}

.box {
  display: grid;
  grid-template-columns: repeat(2, minmax(300px, 1fr));

  @include md {
    grid-template-columns: minmax(0px, 1fr);
  }
}

